import {
  BarChart,
  Call,
  Funnel,
  Map,
  OpportunitiesIcon,
  ReportsChart,
  TableView,
} from 'resources/icons';

export const reportsRoutes = [
  {
    icon: TableView,
    path: '/report/expenses',
    label: 'reportExpenses',
  },
  {
    icon: BarChart,
    path: '/report/dashboard',
    label: 'reportDashboard',
  },
  {
    icon: Call,
    label: 'reportMainCalls',
    children: [
      {
        path: '/report/calls',
        label: 'reportCalls',
      },
      {
        path: '/report/calls-branches',
        label: 'reportCallsBranches',
      },
    ],
  },
  {
    icon: ReportsChart,
    label: 'reportMainOnline',
    children: [
      {
        path: '/report/online',
        label: 'reportOnline',
      },
      {
        path: '/report/offline',
        label: 'reportOffline',
      },
    ],
  },
  {
    icon: Funnel,
    path: '/report/sales-funnel',
    label: 'reportSalesFunnel',
  },
  {
    icon: Map,
    path: '/report/visits',
    label: 'reportVisits',
  },
  {
    icon: OpportunitiesIcon,
    label: 'mainOpportunities',
    children: [
      {
        path: '/report/opportunities',
        label: 'opportunities',
      },
      {
        path: '/report/opportunities-branches',
        label: 'opportunitiesBranches',
      },
      {
        path: '/report/opportunities-branches-refusal',
        label: 'opportunitiesBranchesRefusal',
      },
      {
        path: '/report/opportunities-refusal',
        label: 'opportunitiesRefusal',
      },
    ],
  },
];
