import * as React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Loader, Table, useNotify } from 'ebs-design';
import { Pagination } from 'components/organisms';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';

import { useOpportunitiesAPI } from 'api';
import { dateFormat, dateFormatAPI, dateTimeFormat, format } from 'libs';
import { defaultFilters } from 'utils';
import { Column, FilterType, OpportunityType, Properties, Results } from 'types';

import { OpportunitiesFilter } from '../components';

export const Opportunities: React.FC = () => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const notify = useNotify();
  const history = useHistory();

  const { updateQuery } = useQueryUpdate();

  const { getOpportunities } = useOpportunitiesAPI();

  const { data, isLoading } = useQuery<Results<OpportunityType>>(
    [
      'opportunities',
      {
        ...defaultFilters,
        ...params,
        date: undefined,
        start_date: params.start_date && format(params.start_date, dateFormatAPI, dateFormat),
        end_date: params.end_date && format(params.end_date, dateFormatAPI, dateFormat),
      },
    ],
    getOpportunities,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const handleFilter = (type: FilterType, field: string): FilterType => {
    updateQuery({ ...params, ordering: type ? `${type === 'desc' ? '-' : ''}${field}` : type });
    return type;
  };

  const columns: Column<OpportunityType>[] = React.useMemo(
    () => [
      {
        title: t('lead.leadName'),
        onFilter: (type) => handleFilter(type, 'lead'),
        render: ({ lead }): React.ReactNode => lead?.name,
      },
      {
        title: t('contracts.owner'),
        onFilter: (type) => handleFilter(type, 'owner'),
        render: ({ owner }): React.ReactNode => owner && `${owner?.first_name} ${owner?.last_name}`,
      },
      {
        title: t('opportunities.refusalReasons'),
        onFilter: (type) => handleFilter(type, 'refusal_reason'),
        render: ({ refusal_reason }) => <div className="medium">{refusal_reason?.name}</div>,
      },
      {
        title: t('lead.created_at'),
        onFilter: (type) => handleFilter(type, 'created_at'),
        render: ({ created_at }) =>
          created_at ? <div className="medium">{format(created_at, dateTimeFormat)}</div> : '---',
      },
      {
        title: t('lead.contractValue'),
        onFilter: (type) => handleFilter(type, 'value'),
        render: ({ currency, value }) => (
          <div className="medium">{`${currency?.symbol} ${value}`}</div>
        ),
      },
      {
        title: t('lead.status'),
        onFilter: (type) => handleFilter(type, 'status'),
        render: ({ status }) => <div className="medium">{t(`statuses.${status}`)}</div>,
      },
    ],
    [t, data, params],
  );

  const onRow = ({ lead }): Properties => {
    return {
      onClick: () => {
        history.push(`/lead/${lead.id}`);
      },
    };
  };

  return (
    <>
      <OpportunitiesFilter count={data?.count} />

      <Card>
        <Loader loading={isLoading} size="regular">
          <Table
            emptyText={t('main.noData')}
            onRow={onRow}
            data={data?.results}
            columns={columns}
            className="customers-table no-border"
          />
        </Loader>

        <Card.Footer>
          <Pagination size={data?.total_pages || 0} />
        </Card.Footer>
      </Card>
    </>
  );
};
