import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSetState } from 'react-use';
import { Icon, Select, Space } from 'ebs-design';
import { useQuery } from 'react-query';

import { Filters } from 'components/molecules';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { List, OpportunityStatus, Results, User } from 'types';
import { useRolePermission } from 'hooks/useRolePermission';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { defaultNonPagination, formatSelectValueToNumber } from 'utils';
import { useBranchesAPI, useUsersAPI } from 'api';
import { UserAlone, Map, List as ListIcon } from 'resources/icons';

interface StateProps {
  search?: string;
  date?: string[];
  start_date?: string;
  end_date?: string;
  page?: number;
}

interface LeadFilterProps {
  count?: number;
}

export const OpportunitiesFilter: React.FC<LeadFilterProps> = ({ count }) => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();
  const { getBranches } = useBranchesAPI();
  const { getUsers } = useUsersAPI();

  const [branchesFilter, setBranchesFilter] = useSetState(defaultNonPagination);

  const [filters, setFilters] = useSetState<StateProps>({
    ...params,
    page: params.page ? Number(params.page) : 1,
  });
  const [usersFilter, setUsersFilter] = useSetState(defaultNonPagination);

  const { data: users } = useQuery<Results<User>>(
    [
      'users',
      {
        ...usersFilter,
      },
    ],
    getUsers,
  );

  const { data: branches } = useQuery<Results<List>>(['branches', branchesFilter], getBranches);

  React.useEffect(() => {
    const { date, ...rest } = filters;
    const [start_date, end_date] = date || [];

    updateQuery({
      ...rest,

      ...(start_date && { start_date }),
      ...(end_date && { end_date }),
      ...(date && { date }),

      per_page: params.per_page,
    });
  }, [filters]);

  const handleFilters = (value): void => {
    setFilters({ ...value, page: 1 });
  };

  return (
    <>
      <Filters className="mb-0" onChange={handleFilters}>
        <Space justify="start">
          <Space>
            <h3 className="title">{`${t(`reports.opportunities`)}${
              count ? ` (${count})` : ``
            }`}</h3>
          </Space>
          <Filters.Search field="search" value={params.search} placeholder={t('filter.search')} />
        </Space>

        <Space justify="end">
          {useRolePermission([UserRoleCRM.manager, UserRoleCRM.admin, UserRoleCRM.expert]) && (
            <Filters.Select
              prefix={<Icon component={UserAlone} />}
              placeholder={t('filter.creditExpert')}
              value={params.owner}
              field="owner"
            >
              <Select.Options>
                {users?.results?.map((item, key) => (
                  <Select.Options.Item key={`${key}-user`} value={item.id}>
                    {item?.first_name || item?.last_name
                      ? `${item?.first_name} ${item?.last_name}`
                      : item.email}
                  </Select.Options.Item>
                ))}
              </Select.Options>
              <Select.Pagination
                count={users?.count || 0}
                limit={usersFilter.per_page}
                page={usersFilter.page}
                setPage={(page) => setUsersFilter({ page })}
                mode="scroll"
              />
            </Filters.Select>
          )}

          <Filters.Select
            placeholder={t('filter.status')}
            value={params.status}
            field="status"
            prefix={<Icon component={ListIcon} />}
          >
            <Select.Options>
              {Object.values(OpportunityStatus).map((item, key) => (
                <Select.Options.Item key={`${key}-type`} value={item}>
                  {t(`opportunities.${item}`)}
                </Select.Options.Item>
              ))}
            </Select.Options>
          </Filters.Select>

          <Filters.Select
            prefix={<Icon component={Map} />}
            placeholder={t('filter.branch')}
            value={formatSelectValueToNumber?.(params?.lead__branch)}
            field="lead__branch"
          >
            <Select.Options>
              {branches?.results?.map((item, key) => (
                <Select.Options.Item key={`${key}-branch`} value={item.key}>
                  {item.label}
                </Select.Options.Item>
              ))}
            </Select.Options>

            <Select.Pagination
              count={branches?.count || 0}
              limit={branchesFilter?.per_page}
              page={branchesFilter?.page}
              setPage={(page) => setBranchesFilter({ page })}
              mode="scroll"
            />
          </Filters.Select>

          <Filters.DatePickerFilter
            field="date"
            type="range"
            value={params.created_at}
            placeholder={t('filter.created_at')}
          />
        </Space>
      </Filters>
    </>
  );
};
