import * as React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Loader, Table, useNotify } from 'ebs-design';
import { Pagination } from 'components/organisms';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { LeadFilter } from '../Filters/LeadFilter';
import { useCustomersAPI } from 'api';
import { dateFormat, dateFormatAPI, dateTimeFormat, format } from 'libs';
import { defaultFilters } from 'utils';
import { Column, Customer, FilterType, Properties, Results } from 'types';

export const Leads: React.FC = () => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const notify = useNotify();
  const history = useHistory();

  const { updateQuery } = useQueryUpdate();

  const { getCustomers } = useCustomersAPI();

  const { data: customers, isLoading } = useQuery<Results<Customer>>(
    [
      'customers',
      {
        ...defaultFilters,
        ...params,
        signed: undefined,
        deadline: undefined,
        deadline_from:
          params.deadline_from && format(params.deadline_from, dateFormatAPI, dateFormat),
        deadline_to: params.deadline_to && format(params.deadline_to, dateFormatAPI, dateFormat),
        signed_from: params.signed_from && format(params.signed_from, dateFormatAPI, dateFormat),
        signed_to: params.signed_to && format(params.signed_to, dateFormatAPI, dateFormat),
      },
    ],
    getCustomers,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const handleFilter = (type: FilterType, field: string): FilterType => {
    updateQuery({ ...params, ordering: type ? `${type === 'desc' ? '-' : ''}${field}` : type });
    return type;
  };

  const columns: Column<Customer>[] = React.useMemo(
    () => [
      {
        title: t('lead.leadName'),
        onFilter: (type) => handleFilter(type, ' lead_name'),
        render: ({ lead }): React.ReactNode => lead?.name,
      },
      {
        title: t('contacts.phoneNumber'),
        onFilter: (type) => handleFilter(type, 'lead_contact__phone_number'),
        render: ({ lead }) => <div className="medium">{lead?.contact?.phone_number}</div>,
      },
      {
        title: t('lead.contractNumber'),
        onFilter: (type) => handleFilter(type, 'number'),
        render: ({ number }) => <div className="medium">{number}</div>,
      },
      {
        title: t('lead.contractValue'),
        onFilter: (type) => handleFilter(type, 'value'),
        render: ({ currency, value }) => (
          <div className="medium">{`${currency?.symbol} ${value}`}</div>
        ),
      },
      {
        title: t('lead.signedDate'),
        onFilter: (type) => handleFilter(type, 'signed_at'),
        render: ({ signed_at }) =>
          signed_at ? <div className="medium">{format(signed_at, dateTimeFormat)}</div> : '---',
      },
      {
        title: t('lead.expirationDate'),
        onFilter: (type) => handleFilter(type, 'deadline_at'),
        render: ({ deadline_at }) =>
          deadline_at ? <div className="medium">{format(deadline_at, dateTimeFormat)}</div> : '---',
      },
      {
        title: t('lead.balanceLoan'),
        onFilter: (type) => handleFilter(type, 'balance_loan'),
        render: ({ currency, balance_loan }) => (
          <div className="medium">{`${currency?.symbol} ${balance_loan}`}</div>
        ),
      },
      {
        title: t('lead.balanceOverdue'),
        onFilter: (type) => handleFilter(type, 'balance_overdue'),
        render: ({ currency, balance_overdue }) => (
          <div className="medium">{`${currency?.symbol} ${balance_overdue}`}</div>
        ),
      },
      {
        title: t('lead.outstandingDays'),
        onFilter: (type) => handleFilter(type, 'outstanding_days'),
        render: ({ outstanding_days }) => <div className="medium">{outstanding_days}</div>,
      },
    ],
    [t, customers, params],
  );

  const onRow = ({ lead }): Properties => {
    return {
      onClick: () => {
        history.push(`/lead/${lead.id}`);
      },
    };
  };

  return (
    <div>
      <LeadFilter count={customers?.count} />
      <Card>
        <Loader loading={isLoading} size="regular">
          <Table
            emptyText={t('main.noData')}
            onRow={onRow}
            data={customers?.results}
            columns={columns}
            className="customers-table no-border"
          />
        </Loader>
        <Card.Footer>
          <Pagination size={customers?.total_pages || 0} />
        </Card.Footer>
      </Card>
    </div>
  );
};
