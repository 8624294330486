import { useContext } from 'react';
import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { buildRequestInit } from 'hooks/useFetcher/utils';
import { SessionContext } from 'contexts/SessionContext';
import { getRequest, stringifyUrl } from 'utils';
import { Properties } from 'types';

export const useOpportunitiesAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);
  const { session, apiConfig } = useContext(SessionContext);

  if (!apiConfig) {
    throw new Error('Services configuration was not set, please check Bootstrap component');
  }

  const service = apiConfig[ServiceTypes.CRM];
  if (!service) {
    throw new Error(`Service config missing for ${ServiceTypes.CRM}`);
  }

  return {
    getLeadOpportunities: ({ queryKey: [, { id }] }) => {
      return fetcher(`/leads/${id}/opportunities`, getRequest);
    },

    getOpportunities: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/opportunities`, query), getRequest);
    },

    getOpportunityById: ({ queryKey: [, { id }] }) => {
      return fetcher(`/opportunities/${id}`, getRequest);
    },
    postOpportunity: (data) => {
      return fetcher('/opportunities', {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    patchOpportunity: ({ id, ...data }) => {
      return fetcher(`/opportunities/${id}`, {
        data,
        request: {
          method: 'PATCH',
        },
      });
    },
    putOpportunity: ({ id, ...data }) => {
      return fetcher(`/opportunities/${id}`, {
        data,
        request: {
          method: 'PUT',
        },
      });
    },
    deleteOpportunity: (id: number) => {
      return fetcher(`/opportunities/${id}`, {
        request: {
          method: 'DELETE',
        },
      });
    },
    declineAllOpportunity: (id, data) => {
      return fetcher(`/leads/${id}/opportunities/decline`, {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    download1C: async (data) => {
      const init = buildRequestInit({
        req: {
          body: JSON.stringify(data),
          method: 'POST',
        },
        session,
        serviceToken: service?.token,
      });

      const req = new Request(`${service.baseUrl}/contracts/report?type=pdf`, init);
      return await fetch(req);
    },
  };
};
